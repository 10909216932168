<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <router-link to="/profile" class="back-profile">
        <img src="@/assets/img/arrow-round-back.svg" alt="back" /> Назад
      </router-link>
      <div class="wrapper-notice">
        <h2>Настройка уведомлений</h2>
        <div class="wrapper-profile-form">
          <div class="notice-form-flex">
            <div class="notice-element">
              <h4>Вы на THELAK</h4>
              <p>
                Порекомендуем лучшее и сообщим о выходе нового видеоконтента
              </p>
              <div class="flex-notice-button">
                <div>E-mail</div>
                <switches
                  v-model="switchers.recommendation"
                  theme="custom"
                  color="blue"
                  type-bold="true"
                  :emit-on-mount="false"
                />
              </div>
            </div>
          </div>
          <div class="notice-form-flex">
            <div class="notice-element">
              <h4>Новости сервиса</h4>
              <p>Только важные новости о работе сайта. Без спама</p>
              <div class="flex-notice-button">
                <div>E-mail</div>
                <switches
                  v-model="switchers.news"
                  theme="custom"
                  color="blue"
                  type-bold="true"
                  :emit-on-mount="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Switches from "vue-switches";
export default {
  name: "Notification",
  components: { Switches },
  computed: {
    ...mapGetters(["notifications"]),
    switchers: {
      get() {
        return this.notifications;
      },
      set(n) {
        this.setNotifications(n);
      }
    }
  },
  methods: {
    ...mapActions(["getNotifications", "updateNotifications"]),
    ...mapMutations(["setNotifications"]),
    setSwitch() {
      this.updateNotifications(this.switchers);
    }
  },
  created() {
    this.getNotifications();
  },
  watch: {
    "switchers.sales"(n, o) {
      if (n !== o) {
        this.setSwitch();
      }
    },
    "switchers.recommendation"(n, o) {
      if (n !== o) {
        this.setSwitch();
      }
    },
    "switchers.content"(n, o) {
      if (n !== o) {
        this.setSwitch();
      }
    },
    "switchers.news"(n, o) {
      if (n !== o) {
        this.setSwitch();
      }
    }
  }
};
</script>

<style lang="scss">
.vue-switcher {
  margin-right: 23px;
  margin-top: 18px;
  &.vue-switcher-theme--custom {
    &.vue-switcher-color--blue {
      div {
        background-color: #2b2b2b;

        &:after {
          // for the circle on the switch
          background-color: #3436ea;
        }
      }

      &.vue-switcher--unchecked {
        div {
          background-color: #353535;

          &:after {
            background-color: lighten(blue, 10%);
          }
        }
      }
    }
  }
}
</style>
